
import Moralis from "moralis";
import { computed, defineComponent, onMounted, onUnmounted, ref } from "vue";

export default defineComponent({
  name: "Telegram",
  components: {},
  emits: ["providerData"],
  props: {},
  setup(props, { emit }) {
    const userID = ref("");
    const chatID = ref("");
    const userTag = computed(() => {
      const code = getRandomCode();
      return "Notifi-" + code;
    });

    const getRandomCode = () => {
      var chars =
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
      var passwordLength = 8;
      var password = "";
      for (var i = 0; i <= passwordLength; i++) {
        var randomNumber = Math.floor(Math.random() * chars.length);
        password += chars.substring(randomNumber, randomNumber + 1);
      }
      return password;
    };

    const providerData = computed((): Record<string, string | undefined> => {
      return {
        to: userID.value,
        chatID: chatID.value,
        status: "Active",
      };
    });

    const valid = computed(() => {
      return userID.value && chatID.value;
    });

    const updateProviderData = (): void => {
      if (valid.value) {
        emit("providerData", providerData.value);
      } else {
        emit("providerData", null);
      }
    };

    const copyCode = async () => {
      // copy text to clipboard
      if (navigator.clipboard.writeText) {
        await navigator.clipboard.writeText(userTag.value);
      }
    };

    let polling = ref(true);
    const runPoll = async () => {
      const params = { userTag: userTag.value };
      const telegramRes = await Moralis.Cloud.run("telegramMonitor", params);
      if (telegramRes.success && telegramRes.userid && telegramRes.chatID) {
        userID.value = `@${telegramRes.userid}`;
        chatID.value = telegramRes.chatID;
        updateProviderData();
        return true;
      } else {
        return false;
      }
    };

    const startPolling = () => {
      runPoll();
      if (polling.value) {
        setInterval(startPolling, 3000);
      }
    };

    onMounted(() => {
      //startPolling();
    });

    onUnmounted(() => {
      polling.value = false;
    });

    return {
      valid,
      userID,
      userTag,
      providerData,
      polling,
      runPoll,
      startPolling,
      copyCode,
    };
  },
});
