
import Moralis from "moralis";
import { defineComponent } from "vue";

export default defineComponent({
  name: "Email",
  components: {},
  emits: ["providerData"],
  data() {
    return {
      email: "",
      setDefault: true,
      noDefault: false,
    };
  },
  async onMount(): Promise<boolean> {
    const val: any = await Moralis.Cloud.run("emailVerified");
    this.noDefault = val;
    return val;
  },
  computed: {
    noDefaultOld(): boolean {
      const u = Moralis.User.current();
      const em = u.get("email");
      return !em;
    },
    validEmail(): boolean {
      if (/(.+)@(.+){2,}\.(.+){2,}/.test(this.email)) {
        return true;
      } else {
        return false;
      }
    },
    valid(): boolean {
      return this.validEmail;
    },
    providerdata(): Record<string, string | boolean | undefined> {
      return {
        email: this.email,
        setDefault: this.noDefault && this.setDefault,
      };
    },
  },
  methods: {
    updateProviderData(): void {
      if (this.valid) {
        this.$emit("providerData", this.providerdata);
      } else {
        this.$emit("providerData", null);
      }
    },
  },
});
