
import { computed, defineComponent, ref, watchEffect } from "vue";
import { Subscription } from "@/models/Subscription";
import { UserChannel } from "@/models/Channel";
import { useUserChannelsStore } from "@/store/pinia_userChannel";
import {
  SubscriptionTypes,
  SubscriptionTypesName,
  ValueOperatorNames,
} from "@/notifi_types";
import { prettyNumber } from "@/Utilities";
import GroupPicker from "@/components/GroupPicker.vue";
import InlineEdit from "@/components/InlineEdit.vue";
import { Group } from "@/models/Group";

export default defineComponent({
  name: "SubscriptionCard",
  props: {
    subscription: { type: Subscription, required: false },
    showGroup: { type: Boolean, required: false, default: true },
  },
  setup(props) {
    const paused = computed(() => {
      return props.subscription?.status == "paused";
    });
    const channels = ref([] as UserChannel[]);

    const activeSubscription = ref<Subscription>(new Subscription());
    const userChannelsStore = useUserChannelsStore();

    const availableChannels = computed(() => {
      const myChannels = userChannelsStore.activeChannels;
      const chans: UserChannel[] = [];
      for (let i = 0; i < myChannels.length; i++) {
        let cand = myChannels[i];
        let existing = channels.value.find((elem) => {
          return elem.id == cand.id;
        });
        if (!existing) {
          chans.push(cand);
        }
      }
      return chans;
    });

    watchEffect(async () => {
      if (props.subscription) {
        activeSubscription.value = props.subscription;
        channels.value = await props.subscription.channels();
      }
    });

    return { paused, channels, activeSubscription, availableChannels };
  },
  components: { GroupPicker, InlineEdit },
  data() {
    return {
      currentSubscription: this.subscription || new Subscription(),
      channelsDescription: "",
      pausing: false,
      destroying: false,
    };
  },
  computed: {
    protocolName(): string {
      const p = this.currentSubscription.protocol;
      return p?.name;
    },
    protocolWebsite(): string {
      const p = this.currentSubscription.protocol;
      if (p) {
        return p.website;
      }
      return "";
    },
    typeIcon(): string {
      const t = this.currentSubscription.subscriptionType;
      if (t == SubscriptionTypes.wallet) {
        return "account_balance_wallet";
      } else if (t == SubscriptionTypes.protocol) {
        return "announcement";
      } else if (t == SubscriptionTypes.contract) {
        return "gavel";
      } else if (t == SubscriptionTypes.position) {
        return "radar";
      }
      return "";
    },
    showProtocol(): boolean {
      const subType = this.subscriptionType as unknown;
      return subType == "Protocol Alerts" || subType == "Smart Contracts";
    },
    contractDescription(): string {
      const c = this.currentSubscription.contract;
      return c?.description || "";
    },
    contractActivity(): string {
      const act = this.currentSubscription.contractActivity;
      return act?.name || "";
    },
    activityName(): string {
      const act = this.currentSubscription.contractActivity;
      return act?.type == "Event" ? "Event" : "Activity" || "Activity";
    },
    allowEdit(): boolean {
      return true;
    },
    generalTypeName(): string {
      return this.currentSubscription.generalTypeName();
    },
    contractURL(): string {
      if (this.currentSubscription.contract) {
        return this.currentSubscription.contract.contractURL;
      } else {
        return "";
      }
    },
    internalContractShortAddress(): string {
      if (this.currentSubscription) {
        return this.currentSubscription.internalShortContractAddress();
      } else {
        return "";
      }
    },
    internalContractURL(): string {
      if (this.currentSubscription) {
        return this.currentSubscription.internalContractURL();
      } else {
        return "";
      }
    },
    fromAddress(): string {
      if (this.currentSubscription) {
        const addr = this.currentSubscription.fromAddress;
        return addr;
      } else {
        return "";
      }
    },
    toAddress(): string {
      if (this.currentSubscription) {
        const addr = this.currentSubscription.toAddress;
        return addr;
      } else {
        return "";
      }
    },
    fromAddressShort(): string {
      if (this.currentSubscription) {
        const addr = this.currentSubscription.fromAddress;
        return this.shortAddress(addr);
      } else {
        return "";
      }
    },
    toAddressShort(): string {
      if (this.currentSubscription) {
        const addr = this.currentSubscription.toAddress;
        return this.shortAddress(addr);
      } else {
        return "";
      }
    },
    valueDescription(): string {
      const sub = this.currentSubscription;
      if (!sub) return "";
      const t = sub.subscriptionType;
      if (t == SubscriptionTypes.position) {
        return sub.positionDescription();
      }
      if (t == SubscriptionTypes.wallet) {
        if (sub.valueOperator && sub.value) {
          const val = prettyNumber(sub.value);
          const op = ValueOperatorNames[sub.valueOperator];
          return `${op} ${val}`;
        }
      }
      return "";
    },
    name: {
      get(): string {
        return this.currentSubscription.name;
      },
      set(val: string) {
        this.currentSubscription.name = val;
        this.currentSubscription.save();
      },
    },
    showChannels(): boolean {
      return this.group == undefined;
    },
    group: {
      get(): Group {
        return this.currentSubscription.group as Group;
      },
      set(val: Group) {
        this.currentSubscription.group = val;
        this.currentSubscription.save();
      },
    },
    subscriptionType(): SubscriptionTypesName {
      return this.currentSubscription.subscriptionType;
    },
    description: {
      get(): string {
        return this.currentSubscription.description;
      },
      set(val: string) {
        this.currentSubscription.description = val;
      },
    },
    pauseToggleIcon(): string {
      if (this.paused) return "pause";
      else return "play_arrow";
    },
    pausePopoverText(): string {
      if (this.paused) return "Paused : Click to Resume";
      else return "Active : Click to Pause";
    },
  },
  methods: {
    removeChannel(chan: UserChannel) {
      if (this.channels.length < 2) {
        alert("You can't remove the last channel on a subscription");
      } else {
        let text = `Are you sure you want to remove '${chan.name} from this subscription?`;
        if (confirm(text) == true) {
          chan.removeSubscription(this.activeSubscription as Subscription);
        }
      }
    },
    addChannel(chan: UserChannel) {
      chan.addSubscription(this.activeSubscription as Subscription);
    },
    shortAddress(addr: string) {
      if (!addr || addr.length < 6) return "";
      return addr.slice(1, 6) + "..." + addr.substring(addr.length - 4);
    },
    async destroy(): Promise<void> {
      this.destroying = true;
      this.currentSubscription.destroy();
    },
    async edit(): Promise<void> {
      const path = this.$route.fullPath;
      if (this.subscriptionType == "Position") {
        this.$router.push({
          path: `/subscription/position/${this.subscription?.id}`,
          query: { returnPath: path },
        });
      } else if (this.subscriptionType == "Protocol Alerts") {
        this.$router.push({
          path: `/subscription/protocol/${this.subscription?.id}`,
          query: { returnPath: path },
        });
      } else if (this.subscriptionType == "Smart Contracts") {
        this.$router.push({
          path: `/subscription/contract/${this.subscription?.id}`,
          query: { returnPath: path },
        });
      } else if (this.subscriptionType == "My Wallet") {
        this.$router.push({
          path: `/subscription/wallet/${this.subscription?.id}`,
          query: { returnPath: path },
        });
      }
    },
    async togglePause(): Promise<void> {
      this.pausing = true;
      if (this.paused) {
        this.currentSubscription.set("status", "active");
      } else {
        this.currentSubscription.set("status", "paused");
      }
      await this.currentSubscription.save();
      this.pausing = false;
    },
  },
});
