import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex sm12" }
const _hoisted_2 = { class: "row pt-2" }
const _hoisted_3 = {
  key: 0,
  class: "row"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_input = _resolveComponent("va-input")!
  const _component_va_checkbox = _resolveComponent("va-checkbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_va_input, {
        class: "mb-2",
        onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateProviderData())),
        onKeyup: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateProviderData())),
        modelValue: _ctx.email,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.email) = $event)),
        label: "Enter Email Address",
        placeholder: "user@gmail.com",
        error: !this.valid
      }, null, 8, ["modelValue", "error"])
    ]),
    (_ctx.noDefault)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_va_checkbox, {
            onChanged: _ctx.updateProviderData,
            modelValue: _ctx.setDefault,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.setDefault) = $event)),
            label: "Make this my default email"
          }, null, 8, ["onChanged", "modelValue"])
        ]))
      : _createCommentVNode("", true)
  ]))
}