
import { defineComponent } from "vue";

export default defineComponent({
  name: "Discord",
  components: {},
  emits: ["providerData"],
  data() {
    return {
      discordUser: "",
    };
  },
  computed: {
    validUser(): boolean {
      return this.discordUser != "" && this.discordUser.length > 3;
    },
    valid(): boolean {
      console.log(`Cleanup valid in Discord to enable this channel`);
      return this.validUser && false;
    },
    providerdata(): Record<string, string | undefined> {
      return {
        username: this.discordUser,
      };
    },
  },
  methods: {
    updateProviderData(): void {
      //console.log(`Update PD ${this.valid}--${this.providerdata}`);
      if (this.valid) {
        this.$emit("providerData", this.providerdata);
      } else {
        this.$emit("providerData", null);
      }
    },
  },
});
