import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex sm12" }
const _hoisted_2 = { class: "row pt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_input = _resolveComponent("va-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_va_input, {
        class: "mb-4",
        onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateProviderData())),
        onKeyup: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateProviderData())),
        modelValue: _ctx.newNumber,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newNumber) = $event)),
        label: "U.S. Phone Number",
        placeholder: "412.555.1212",
        error: !this.valid
      }, null, 8, ["modelValue", "error"])
    ])
  ]))
}